// Add tracking on element change
const addHoverTracking = () => {
  const targetNodes = document.querySelectorAll('.expandable-dropdown');
  const config = { attributes: true, childList: false, subtree: false };
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.target.classList.contains('checked')) {
        GustoAnalytics.track({
          eventCategory: window.SNOWPLOW_EVENT_CATEGORIES.VISITOR_EVENT,
          eventName: 'NavHover',
          data: {
            id: mutation.target.id,
          },
        });
      }
    });
  });

  targetNodes.forEach(node => observer.observe(node, config));
};

window.addEventListener(
  'load',
  () => {
    addHoverTracking();
  },
  { once: true }
);
